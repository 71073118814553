<template>
    <div class="create_new_inquiry">
        <!-- Personal Information -->
        <validation-observer ref="personalInfoForm">
        <b-card
            title="Personal Information"
        >

            <!-- form -->
            <b-form>
                <b-row class="personal_information_wrapper">
                    <!-- image -->
                    <b-col md="1" class="afterUploadImg">
                        <div class="row">
                        <b-col md="12">
                            <img v-if="previewImage.length!=0" :src="previewImage" alt="profile image" width="160px" height="145px" class="profile_img">
                            <img v-else :src="imageUrl" v-show="imageUrl!==''" alt="profile image" width="160px" height="145px" class="profile_img">
                            <b-spinner variant="primary" v-if="uploadingImage" large style="position: absolute;z-index:999; top:50px;left:40%"/>
                        </b-col>
                        <b-col md="12">
                            <validation-provider
                                #default="{ errors }"
                                name="image file"
                                rules=""
                            >
                                <b-form-file
                                    id="imagePicker"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    accept="image/*"
                                    @change="checkFile"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <input type="text" v-model="previewImage" v-show="false">
                                <small class="text-danger" v-if="isImage">{{ errors[0] }}</small>
                                <small class="text-danger" v-else>Please select an image file.</small>
                            </validation-provider>
                        </b-col>
                        </div>
                        <!-- <validation-provider
                            #default="{ errors }"
                            name="image file"
                            rules="required"
                        >
                            <b-form-file
                                id="imagePicker"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                accept="image/*"
                                @change="checkFile"
                                :state="errors.length > 0 ? false:null"
                            />
                             <img src='@/assets/images/icons/onedrive.png' class="upload_img_inner">
                            <input type="text" v-model="previewImage" v-show="false">
                            <small class="text-danger" v-if="isImage">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Please select an image file.</small>
                        </validation-provider> -->
                    </b-col>
                    <b-col md="11">
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="firstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="firstName=firstName.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="lastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="lastName=lastName.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Personal Email -->
                            <b-col md="3">
                                <b-form-group label="Personal Email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="personalemail"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        v-model="personalEmail"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="personalEmail=personalEmail.toLowerCase().trim()"
                                    />
                                    <small class="text-danger" v-if="!checkEmail">{{ errors[0] }}</small>
                                    <small class="text-danger" v-else>This email is already user.</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Company Email -->
                            <b-col md="3">
                                <b-form-group label="Company Email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="companyemail"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        v-model="companyEmail"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        disabled
                                        @blur="companyEmail=companyEmail.toLowerCase().trim()"
                                    />
                                    <small class="text-danger" v-if="!checkCompanyEmail">{{ errors[0] }}</small>
                                    <small class="text-danger" v-else>This email is already user.</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Persoanal Phone No. -->
                            <b-col md="3">
                                <b-form-group label="Phone No." class="position-relative cls_phoneno_wrapper">
                                    <span class="phoneno_prefix"><phoneCountryCodeVue @country-selected="onCountrySelected" :selectedCode="selectedCountry"/></span>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="phone no."
                                        rules="required|integer"
                                    >
                                        <b-form-input
                                            v-model="personalPhoneNo"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter number"
                                            @blur="personalPhoneNo=personalPhoneNo.trim()"
                                            @keypress="isOnlyInteger"
                                            @input="checkValidPhoneNumber"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="errors.length == 0 && !validNumberOrNot">Please provide valid phone number.</small>
                                    </validation-provider>
                                </b-form-group>
                                <span class="phoneconfirmation" v-if="currenUserObject.role == 'admin'">
                                    <b-form-checkbox
                                        v-model="isPhoneNumberShow"
                                    >
                                    <span>Show phone number to student</span>

                                    </b-form-checkbox>
                                </span>
                            </b-col>

                            <!-- Emergency Phone No. -->
                            <b-col md="3">
                                <b-form-group label="Emergency Phone No." class="position-relative cls_phoneno_wrapper">
                                    <span class="phoneno_prefix"><phoneCountryCodeVue @country-selected="onCountrySelectedForEmergency"  :selectedCode="selectedCountryEmergency"/></span>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="emergency phone no."
                                        rules="required|integer"
                                    >
                                        <b-form-input
                                            v-model="emergencyPhoneNo"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter number"
                                            @blur="emergencyPhoneNo=emergencyPhoneNo.trim()"
                                            @keypress="isOnlyInteger"
                                            @input="checkValidPhoneNumberEmergency"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="errors.length == 0 && !validNumberOrNotEmergency">Please provide valid phone number.</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date of Birth -->
                            <b-col md="3">
                                <b-form-group label="Date of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="date of birth"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="dob"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        :max="getMaxDate()"
                                        @change="changeBirthDate($event,'dob')"
                                        :state="errors.length > 0 ? false:null"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Gender -->
                            <b-col md="3">
                                <b-form-group label="Gender">
                                <validation-provider
                                    #default="{ errors }"
                                    name="gender"
                                    rules="required"
                                >
                                <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                <!-- <div> -->
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Male"
                                    >
                                        Male
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Female"
                                    >
                                        Female
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="gender"
                                        plain
                                        name="gender"
                                        value="Other"
                                    >
                                        Other
                                    </b-form-radio>
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date of Joining -->
                            <b-col md="3">
                                <b-form-group label="Joining Date">
                                <validation-provider
                                    #default="{ errors }"
                                    name="date of joining"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="dateOfJoining"                                        
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                        @change="changeBirthDate($event,'doj')"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Role -->
                            <b-col md="3">
                                <b-form-group label="Employee Role">
                                <validation-provider
                                    #default="{ errors }"
                                    name="role"
                                    :rules="roleOptions.length!=1?'required':''"
                                >
                                    <b-form-select
                                        v-model="role"
                                        :options="roleOptions"
                                        :disabled="roleOptions.length==1"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Select Representative -->
                            <b-col md="3">
                                <b-form-group label="Representative">
                                <validation-provider
                                    #default="{ errors }"
                                    name="representative"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="representative"
                                        :options="representativeOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Aadhar Card No. -->
                            <b-col md="3">
                                <b-form-group label="Aadhar Card No.">
                                <validation-provider
                                    #default="{ errors }"
                                    name="aadhar card"
                                    rules="required|integer|min:12|max:12"
                                >
                                    <b-form-input
                                        v-model="aadharCardNo"
                                        name="aadhar card"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter number"
                                        @blur="aadharCardNo=aadharCardNo.trim()"
                                        @keypress="isOnlyInteger"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <!-- Address -->
        <b-card
            title="Address"
        >
            <!-- form -->
            <b-form>
                <b-row>
                    <!-- Address Line 1 -->
                    <b-col md="3">
                        <b-form-group label="Address Line 1">
                        <validation-provider
                            #default="{ errors }"
                            name="address line 1"
                            rules="required"
                        >
                            <b-form-input
                                v-model="addressLine1"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="addressLine1=addressLine1.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Address Line 2 -->
                    <b-col md="3">
                        <b-form-group label="Address Line 2">
                        <validation-provider
                            #default="{ errors }"
                            name="address line 2"
                            rules=""
                        >
                            <b-form-input
                                v-model="addressLine2"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="addressLine2=addressLine2.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Country -->
                    <b-col md="3">
                        <b-form-group label="Country">
                        <validation-provider
                            #default="{ errors }"
                            name="country"
                            rules="required"
                        >
                            <b-form-select
                                v-model="country"
                                :options="countryOptions"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Select"
                                @change="getStates()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- State -->
                    <b-col md="3">
                        <b-form-group label="State">
                        <validation-provider
                            #default="{ errors }"
                            name="state"
                            rules="required"
                        >
                            <b-form-select
                                v-model="state"
                                :disabled="!country"
                                :options="stateOptions"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Select"
                                @change="getCities()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- City -->
                    <b-col md="3">
                        <b-form-group label="City">
                        <validation-provider
                            #default="{ errors }"
                            name="city"
                            rules="required"
                        >
                            <b-form-select
                                v-model="city"
                                :disabled="!state"
                                :options="cityOptions"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Pincode -->
                    <b-col md="3">
                        <b-form-group label="Pincode">
                        <validation-provider
                            #default="{ errors }"
                            name="pincode"
                            rules="required"
                        >
                            <b-form-input
                                v-model="pincode"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Pincode"
                                @blur="pincode=pincode.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <div>
            <b-card>
                <span class="uploadDoc" @click="$refs.inputFile.click()" :aria-disabled="isSpinner==true">
                    <a href="#" :aria-disabled="isSpinner==true">Upload File</a>
                    <b-img :src="require('@/assets/images/icons/onedrive.png')" class="upload_img_inner">
                    </b-img>
                </span>
            </b-card>  
            <b-card>
                <b-row style="margin-bottom: 1.5rem;">     
                    <!-- SEARCH INPUT -->
                    <b-col md="2" sm="4">
                    <b-form-group    
                        class="mb-0"
                    >     
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    />
                    </b-form-group>
                    </b-col>
                    <!-- PAGINATION DROPDOWN -->
                    <b-col md="10" style="display: flex;justify-content: end;">
                    <v-select
                        v-model="perPage"
                        style="width: 90px"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="pageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                    />
                    </b-col>
                </b-row>
                <b-row>
                    <!-- TABLE -->
                    <b-col cols="12">
                    <validation-observer ref="documentName">
                    <b-table
                        striped
                        hover
                        responsive
                        show-empty
                        empty-text="No matching records found"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="employeeDocs"
                        :fields="fields"       
                        :filter="filter"
                        v-model="docTable"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        class="company_document_table position-relative padding-custom"
                    >
                        <template #cell(id)="data">
                            {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                        </template>
                        <template #cell(document_name)="data">
                            <div>
                            <validation-provider
                                #default="{ errors }"
                                :vid="'document name'+((currentPage-1) * perPage + data.index)"
                                name="document Name"
                                rules="required"
                            >
                                <b-form-input
                                v-model="data.item.document_name"
                                placeholder="Enter Text"
                                @blur="data.item.document_name=data.item.document_name.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </div>
                        </template>

                        <template #cell(file)="data">
                            {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                        </template>

                        <template #cell(updatedAt)="data">
                            <span v-if="data.item.updatedAt">
                                {{new Date(data.item.updatedAt.seconds * 1000).getDate()||new Date(data.item.updatedAt).getDate()}} {{months[new Date(data.item.updatedAt.seconds * 1000).getMonth()||new Date(data.item.updatedAt).getMonth()]}}, {{new Date(data.item.updatedAt.seconds * 1000).getFullYear()||new Date(data.item.updatedAt).getFullYear()}}
                            </span>
                            <span v-else>
                                --
                            </span>
                        </template>
                        
                        <template #cell(action)="data">
                            <!-- {{data}} -->
                            <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                            <img src='@/assets/images/erflog/Edit.png' :aria-disabled="isSpinner==true" @click="$refs.editFilePicker.click(),selectedDocFile=data" class="action_icon"/>
                            <img src='@/assets/images/erflog/Delete.png' :disabled='isSpinner==true' @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
                            </div>
                            <div v-else>
                            --
                            </div>
                        </template>
                    </b-table>
                    </validation-observer>
                    <!-- <p v-show="allData.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p> -->
                    </b-col>
                     <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- PAGINATION FOOTER -->
                    <b-col
                    cols="12"
                    >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="paginationNo"
                        :per-page="perPage"
                        align="right"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0 custom_pagination"
                    >
                    <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
                    </b-col>
                </b-row>
            </b-card>
            <input type="file" ref="inputFile" id="fileOutput" style="display: none" :disabled='isSpinner==true' @change="checkDocFile" multiple="multiple">
            <!-- DELETE MODEL -->
            <b-modal
                id="delete-field"
                cancel-variant="outline-secondary"
                ok-title="Yes"
                cancel-title="No"
                centered
                title=""
                :no-close-on-backdrop="true"
                @hidden="selectedRemoveData=''"
                v-if="selectedRemoveData!=''"
                @cancel='isSpiner=false'
                @ok="deleteField(selectedRemoveData)"
            >
                <div class="create_account_img">
                <img src="@/assets/images/erflog/DeletePopup.png" alt="">
                </div>
                <div style="text-align: center;" v-if="selectedRemoveData!=''">
                <label class="s_modal_title">Delete Field</label>
                <p>Are you sure you want to delete these document category?</p>
                </div>
            </b-modal>
        </div>
        <input type="file" ref="editFilePicker" id="editFileOutput" style="display: none" :disabled='isSpinner==true' @change="editDocFile">
        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,
  BFormTextarea,BSpinner,BFormCheckbox,

  BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,min_value,alpha,double } from 'vee-validate/dist/rules';
import phoneCountryCodeVue from '@/views/component/phoneCountryCode.vue';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha',alpha)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import { Country, State, City }  from 'country-state-city';

import { dbCollections } from "@/utils/firebaseCollection.js";
import { ValidationFunction } from '@/utils/globalValidations.js';
import { removeImage,uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
const db = firebase.firestore()
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import {CountriesLength} from "@/utils/countries.js"
import createPhoneNumberRegex from '@/utils/makeRegexForVal.js';
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        BSpinner,
        vSelect,
        BFormCheckbox,
        phoneCountryCodeVue
    },
    data() {
        return {
             CountriesLength,
            uploadingImage:false,
            docId:'',
            allEmails:[],
            allCompanyEmails:[],
            roleOptions: [
                { value: null, text: 'Select' },
                // { value: 'employee', text: 'Employee' },
                // { value: 'SS', text: 'SS' },
            ],
            countryOptions: [{value:null,text:'Select'}],
            stateOptions: [{value:null,text:'Select'}],
            cityOptions: [{value:null,text:'Select'}],
            isImage:true,
            imageUrl:'',
            imageName:"",
            imgFile:[],
            imageFile:[],
            previewImage:[],
            name: '',
            firstName:'',
            lastName:'',
            role: null,
            personalEmail:"",
            companyEmail:"",
            personalPhoneNo:"",
            isPhoneNumberShow: null,
            emergencyPhoneNo:"",
            dob:"",
            dateOfJoining:"",
            gender:"",
            aadharCardNo:'',

            addressLine1:"",
            addressLine2:"",
            country:null,
            state:null,
            city:null,
            pincode:"",

            oldCompanyEmail:'',

            //Firebase storage Folder name to store files
            folderName:'Employee Images',
            previuseEmpRole:null,
            allUserRole:[],


            //Employee Documents
            docFolderName:'Employee Documents',
            employeeDocs:[],
            prevDocs:[],
            isSpinner:false, 
            perPage: 10,
            pageOptions: [10, 25, 50],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            selectedDocFile:'',
            fields: [
                {
                    key: 'id', label: 'No.',
                },
                // {
                //   key: 'docExtension', label: 'Icon',
                // },
                {
                    key: 'document_name', label: 'Document Name'
                },
                {
                    key: 'imageName', label: 'File Name',sortable: true
                }, 
                {
                  key: 'updatedAt', label: 'Date Added',sortable: true
                }, 
                {
                key: 'action', label: 'Action',
                },      
            ],
            months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            documentName:'',
            allData:[],
            selectedRemoveData:{},
            paginationNo:0,
            docTable:[],
            oldPersonalEmail:'',
            oldRepresentation:null,
            representativeOptions: [{value:null,text:'Select',disabled:true}],
            representative:null,
            currenUserObject:{},
            employeeArray:[],
            selectedCountry: null,
            validNumberOrNot: true,
            selectedCountryEmergency: null,
            validNumberOrNotEmergency: true,
        }
    },
    created() {
        var self = this

        self.docId = self.$route.params.id

        if(!self.docId)
        {
            self.$router.push({name:'employee-mgmt'})
        }

        var countries = Country.getAllCountries()
        self.countryOptions=[{value:null,text:'Select'}]
        countries.forEach(data=>{
            self.countryOptions.push({value:data,text:data.name})
        })
        // console.log("Co",self.countryOptions,countries)

        self.$root.$on('saveNewEmployee',()=>{
            // console.log('save called')
            if(self.$refs.personalInfoForm)
            {
                let eId = typeof self.representative == "string" ? self.representative : self.representative?.id
                let employeeData = this.employeeArray.find((e)=>{return e.id == eId})
                self.representative = {id: employeeData.id,name: employeeData.name, eid: employeeData.employeeId||'', phoneNo: employeeData.personalPhoneNo||''}
                self.saveEmployeeData()
            }
        })
        self.$root.$on('cancelNewEmployee',()=>{
            // console.log('cancel called')
            if(self.$refs.personalInfoForm)
            {
                self.$router.push({name:'employee-information',params:{name:self.$route.params.name,id:self.$route.params.id}})
            }
        })
        // self.$root.$on('closeInquiry',()=>{
        //     console.log('close called')
        //     if(self.$refs.personalInfoForm)
        //     {
        //         self.$refs.personalInfoForm.reset()
        //         self.$router.push({name:'student-inquiry'})
        //     }
        // })

        // self.getEmployeeId(res=>{
        //     console.log(res)
        // })

        //Get user Emails
        db
            .collection(dbCollections.USERS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                let representativeArray = [];
                let employeeArry = [];
                querySnapshot.forEach(doc=>{
                    if(doc.exists && !doc.data().isDeleted)
                    {
                        self.employeeArray.push(doc.data())

                        if(doc.id == firebase.auth().currentUser.uid)
                        {
                            self.currenUserObject = doc.data()
                        }
                        if(!doc.data().isDeleted && doc.data().role == 'employee') {
                            if(doc.data().representative){
                                employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                            } else {
                                employeeArry.push({id:doc.data().id})
                            }
                        }
                        function findRelatedRIds(id) {
                            const relatedRIds = [];
                            relatedRIds.push(id)
                            const visitedIds = new Set();

                            function traverseRelations(currentId) {
                                visitedIds.add(currentId);
                                
                                for (const relation of employeeArry) {
                                    if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                                        relatedRIds.push(relation.id);
                                        traverseRelations(relation.id);
                                    }
                                }
                            }
                            
                            traverseRelations(id);
                            
                            return relatedRIds;
                        }
                        let id = firebase.auth().currentUser.uid
                        representativeArray = findRelatedRIds(id)
                        // employeeArry.forEach((item)=>{
                        //     if(representativeArray.length === 0) {
                        //         if(item.id == firebase.auth().currentUser.uid){
                        //             representativeArray.push(firebase.auth().currentUser.uid)
                        //         }
                        //         if(item.rId){
                        //             if(item.rId == firebase.auth().currentUser.uid){
                        //                 representativeArray.push(item.id)
                        //             }
                        //         }
                        //     } else {
                        //         if(item.rId){
                        //             if(representativeArray.includes(item.rId)){
                        //                 representativeArray.push(item.id)
                        //             }
                        //         }
                        //     }
                        // })
                        if(doc.data().id == self.docId)
                        {
                            self.imageName=doc.data().imageName
                            self.imageUrl=doc.data().imageUrl
                            self.oldCompanyEmail = doc.data().companyEmail
                            self.name=doc.data().name
                            self.firstName = doc.data().firstName
                            self.lastName = doc.data().lastName
                            self.role=doc.data().employeeRole
                            self.personalEmail=doc.data().personalEmail
                            self.oldPersonalEmail = doc.data().personalEmail
                            self.companyEmail=doc.data().companyEmail
                            self.personalPhoneNo=doc.data().personalPhoneNo
                            self.isPhoneNumberShow = doc.data().isPhoneNumberShow !== undefined ? doc.data().isPhoneNumberShow : true
                            self.emergencyPhoneNo=doc.data().emergencyPhoneNo
                            self.dob=doc.data().dob
                            self.dateOfJoining=doc.data().dateOfJoining
                            self.gender=doc.data().gender
                            self.aadharCardNo=doc.data().aadharCardNo

                            self.addressLine1=doc.data().addressLine1
                            self.addressLine2=doc.data().addressLine2
                            self.country=doc.data().country
                            self.getStates()
                            self.state=doc.data().state
                            self.getCities()
                            self.city=doc.data().city
                            self.pincode=doc.data().pincode
                            self.previuseEmpRole = doc.data().employeeRole;
                            self.selectedCountry = doc.data().countryPhoneCode ? doc.data().countryPhoneCode : { "ru": "Индия", "lt": "Indija", "tr": "Hindistan", "en": "India", "flag": "🇮🇳", "code": "IN", "dialCode": "+91", "mask": "99999 99999"}
                            self.selectedCountryEmergency = doc.data().countryPhoneCodeEmergency ? doc.data().countryPhoneCodeEmergency : { "ru": "Индия", "lt": "Indija", "tr": "Hindistan", "en": "India", "flag": "🇮🇳", "code": "IN", "dialCode": "+91", "mask": "99999 99999"}

                            self.employeeDocs=doc.data().employeeDocs||[]
                            self.prevDocs=doc.data().employeeDocs||[]

                            //PAGINATION TOTAL COUNT
                            this.paginationNo = this.employeeDocs.length;
                            self.oldRepresentation = doc.data().representative ? {...doc.data().representative} : null;
                            self.representative = doc.data().representative.id||null
                        }
                        if(doc.data().role.toLowerCase() == 'admin' && doc.data().id == firebase.auth().currentUser.uid) {
                            self.representativeOptions.push({value:doc.data().id,text:doc.data().name})
                        }
                        if(doc.data().role.toLowerCase()=="employee")
                        {
                            if(!doc.data().isDeleted && ((self.currenUserObject.role == 'employee' && representativeArray.includes(doc.data().id) && doc.data().id !== self.docId) || (self.currenUserObject.role == 'admin' && doc.data().id !== self.docId)))
                            {
                                self.representativeOptions.push({value:doc.data().id,text:doc.data().name})
                            }
                            self.allEmails.push(doc.data().personalEmail)
                            self.allCompanyEmails.push(doc.data().companyEmail)                            
                        }
                        else if(doc.data().role.toLowerCase()=="student")
                        {
                            self.allCompanyEmails.push(doc.data().email)
                            self.allEmails.push(doc.data().email)
                        }
                    }
                })
                //this is for preventing A - B and B - A
                let filterArray = employeeArry.filter((data)=>data.rId == self.docId)
                if(filterArray.length > 0){
                    let count = 0;
                    const removeExtras = (row) =>{
                        if(count >= filterArray.length){
                            return;
                        } else {
                            if(row.id){
                                let fIndex = self.representativeOptions.findIndex((data)=>(data.value && data.value.id) == row.id)
                                if(fIndex != -1){
                                    self.representativeOptions.splice(fIndex,1)
                                    count++;
                                    removeExtras(filterArray[count])
                                } else {
                                    count++;
                                    removeExtras(filterArray[count])
                                }
                            } else {
                                count++;
                                removeExtras(filterArray[count])
                            }
                        }
                    }
                    removeExtras(filterArray[count])
                }
                self.representativeOptions = self.representativeOptions.filter(data=>data.value!=null && data.value.id!=self.docId)
                self.representativeOptions.unshift({value:null,text:'Select',disabled:true});
            })
            .catch(error=>{
                console.log(error)
            })

        //Get Roles
        db
            .collection(dbCollections.USERROLES)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                self.allUserRole = [];
                querySnapshot.forEach(doc=>{                    
                    if(doc.exists && !doc.data().isDeleted)
                    { 
                        self.roleOptions.push({
                            text:doc.data().userRoleName,
                            value:{
                                id:doc.id,
                                name :doc.data().userRoleName,
                            }
                        })
                    }
                    //GET ALL DATA FROM USERROLE JYOTI [16-12-21]
                    if(doc.exists && doc.data().isDeleted == false)
                    {
                        self.allUserRole.push(doc.data())
                    }                   
                })
            })
    },
    methods: {
        checkValidPhoneNumber(event) {
            if (this.selectedCountry !== null) {
                let regexPattern;
                const lengths = this.CountriesLength.find((e)=>e.alpha2 === this.selectedCountry.code)?.phone_number_lengths || [10,15]
                regexPattern = createPhoneNumberRegex(lengths)
                this.validNumberOrNot = regexPattern.test(event);
            }

            return this.validNumberOrNot;
        },
        checkValidPhoneNumberEmergency(event) {
            if (this.selectedCountryEmergency !== null) {
                let regexPattern;
                const lengths = this.CountriesLength.find((e)=>e.alpha2 === this.selectedCountryEmergency.code)?.phone_number_lengths || [10,15]
                regexPattern = createPhoneNumberRegex(lengths)
                this.validNumberOrNotEmergency = regexPattern.test(event);
            }

            return this.validNumberOrNotEmergency;
        },
        onCountrySelected(country) {
            this.selectedCountry = country;
        },
        onCountrySelectedForEmergency(country) {
            this.selectedCountryEmergency = country;
        },
        checkDocFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.isImage=true
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
            }
            // this.attachmentImages=[]
            // this.attachments=[]
            files.forEach(data=>{
                var docExt = data.name.split('.')
                if(data.type.split('/')[1]!='jpeg' && data.type.split('/')[1]!='png' && data.type.split('/')[1]!='pdf' && docExt[docExt.length-1]!='doc' && docExt[docExt.length-1]!='docx')
                {
                    this.isImage=false
                    // this.attachmentImages=[]
                }
            })
            if(this.isImage){
                this.isImage=true
                this.pushImage(files)
                // console.log(this.attachments)
            }
            else
            {
                this.$root.$emit('showToastMessage','Please select a PNG/JPG/PDF/DOC file.','danger')
                document.getElementById("imagePicker").value=""
            }
        },
        editDocFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.isImage=true

            // console.log('files',files.length,e)
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
                // this.attachmentImages=[]
                // this.attachments=[]
                files.forEach(data=>{
                    var docExt = data.name.split('.')
                    if(data.type.split('/')[1]!='jpeg' && data.type.split('/')[1]!='png' && data.type.split('/')[1]!='pdf' && docExt[docExt.length-1]!='doc' && docExt[docExt.length-1]!='docx')
                    {
                        this.isImage=false
                        // this.attachmentImages=[]
                    }
                })
                if(this.isImage){
                    this.isImage=true
                    // console.log("Edit")
                    this.employeeDocs.forEach(imgs=>{
                        if(imgs.imageName==this.selectedDocFile.item.imageName && imgs.imageUrl==this.selectedDocFile.item.imageUrl && imgs.document_name==this.selectedDocFile.item.document_name)
                        {
                            // console.log("found",imgs)
                            imgs.updatedAt=new Date()
                            imgs.imageName=files[0].name
                            imgs.imageUrl=files[0]
                            imgs.document_name=''

                        }
                    })

                    this.selectedDocFile=""
                    // console.log(this.attachments)
                }
                else
                {
                    this.$root.$emit('showToastMessage','Please select a PNG/JPG/PDF/DOC file.','danger')
                    document.getElementById("imagePicker").value=""
                    this.selectedDocFile=""
                }
            }
            else
            {
                // console.log("empty")
                this.selectedDocFile=''
                return;
            }
        },
        pushImage(files)
        {
            var self = this
            // console.log(files[0].name)
            
            files.forEach(data=>{
                self.employeeDocs.push({
                    'document_name':'',
                    'updatedAt':new Date(),
                    imageUrl:data,
                    imageName:data.name,
                })
                this.paginationNo = this.employeeDocs.length;
            })

            // console.log(self.employeeDocs)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
            if(this.filter !== ""){
                this.paginationNo = this.docTable.length
            }else{
                this.paginationNo = this.employeeDocs.length
            }
        },
        deleteField(data){
            var index = (this.currentPage-1) * this.perPage + data.index
            this.employeeDocs.splice(index,1)
            this.paginationNo = this.employeeDocs.length;
            this.$refs.documentName.reset();
        },
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        changeBirthDate(event,type){
            var self = this
            // console.log(event,"SEE HERE",self.dob,'typer',type)
            var maxDate = self.getMaxDate().split('-')
            var selectedDate = event.split('-')
            if(type=='dob'){    
                if(selectedDate[0]>maxDate[0])//If Year not valid
                {
                    self.dob = '';
                    return;
                }
                else if(selectedDate[0]==maxDate[0])
                {
                    if(selectedDate[1]>maxDate[1])//If month not valid
                    {
                        self.dob = '';
                        return;
                    }
                    else
                    {
                        if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
                        {
                            self.dob = '';
                            return;
                        }
                    }
                }
            }
            if(type='doj'){
                 if(selectedDate[0]>maxDate[0])//If Year not valid
                {
                    self.dateOfJoining = '';
                    return;
                }
                else if(selectedDate[0]==maxDate[0])
                {
                    if(selectedDate[1]>maxDate[1])//If month not valid
                    {
                        self.dateOfJoining = '';
                        return;
                    }
                    else
                    {
                        if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
                        {
                            self.dateOfJoining = '';
                            return;
                        }
                    }
                }                
            }
        },
        isOnlyDecimal(event) {
            ValidationFunction.OnlyFloatWithNumber(event);
        },
        isOnlyInteger(event) {
            ValidationFunction.OnlyIntegerNumber(event);
        },
        getStates()
        {
            var self = this

            self.state=null
            self.city=null
            
            self.stateOptions=[{value:null,text:'Select'}]
            var states = State.getStatesOfCountry(self.country.isoCode)
            states.forEach(data=>{
                self.stateOptions.push({value:data,text:data.name})
            })
        },
        getCities()
        {
            var self = this

            self.city=null
            
            self.cityOptions=[{value:null,text:'Select'}]
            var cities = City.getCitiesOfState(self.country.isoCode,self.state.isoCode)
            cities.forEach(data=>{
                self.cityOptions.push({value:data,text:data.name})
            })
        },
        //Check if is valid(image) file
        checkFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.isImage=true
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
            }
            // this.attachmentImages=[]
            // this.attachments=[]
            files.forEach(data=>{
                if(data.type.split('/')[0]!='image')
                {
                    this.isImage=false
                    // this.attachmentImages=[]
                }
            })
            if(this.isImage){
                this.isImage=true
                this.createImage(files)
                // console.log(this.attachments)
            }
            else
            { 
                document.getElementById("imagePicker").value=""
            }
        },
        createImage(files) {
            //   var image = new Image();
            
            files.forEach(data=>{
                var vm = this;
                var reader = new FileReader();
    
                reader.onload = (e) => {
                    vm.previewImage.push(e.target.result);
                    vm.imageFile.push(data)
                    // console.log("images")
                    // console.log(vm.previewImage)
                    // console.log(vm.imageFile)
                }
                reader.readAsDataURL(data);
            })
        },

        addNewRow(){
            //add field
            this.fieldOfInterests.push({
                field:null,
                course:null
            })
        },
        removeRow(index)
        {
            //remove field
            this.fieldOfInterests.splice(index, 1)
        },

        getEmployeeId(cb)
        {
            // var self = this
            db
                .collection(dbCollections.USERS)
                .orderBy('createdAt')
                .get()
                .then((querySnapshot)=>{
                    var newEmployeeId=0
                    if(!querySnapshot.empty)
                    {
                        querySnapshot.forEach(doc=>{
                            if(!doc.data().isDeleted && doc.data().role=="employee")
                            {
                                var uniqueId = parseInt(doc.data().employeeId)
                                if(uniqueId>=newEmployeeId)
                                {
                                    newEmployeeId = uniqueId+1
                                }
                                var zeroes = 5-newEmployeeId.toString().length

                                for (let i = 0; i < zeroes; i++) {
                                    newEmployeeId='0'+newEmployeeId
                                }
                                // console.log("new Id",newEmployeeId)
                            }
                        })
                        if(newEmployeeId==0)
                        {
                            newEmployeeId='00001'
                        }
                    }
                    else
                    {
                        newEmployeeId='00001'
                    }
                    cb(newEmployeeId)
                })
        },
        saveEmployeeData() {
            var self = this
            // console.log("Validate",self.$refs.personalInfoForm)
            self.checkValidPhoneNumber(self.personalPhoneNo);
            self.checkValidPhoneNumberEmergency(self.emergencyPhoneNo);
            self.$refs.personalInfoForm.validate().then(success => {
                if (success && !self.checkCompanyEmail && !self.checkEmail && self.validNumberOrNot && self.validNumberOrNotEmergency) {
                    if(self.allDocsValid())
                    {
                        self.name = self.firstName + ' '+ self.lastName
                        //REMOVE UPDATED USERID FROM USER ROLE
                        if(self.previuseEmpRole !== null){
                            if(Object.keys(self.previuseEmpRole).length > 0){
                                if(self.allUserRole.length > 0){
                                    let searchInd = self.allUserRole.findIndex(values=>{
                                        return values.id == self.previuseEmpRole.id  //MATCH ROLE ID
                                    })
                                    if(searchInd > -1){
                                        if(self.allUserRole[searchInd].assignUser.length > 0){
                                            //HERE IF EMPLOYE CHANGE THEN WE REMOVE ID FROM USERROLE ASIGN USER ARRAY
                                            let matchId = self.allUserRole[searchInd].assignUser.findIndex(elem=>{
                                                return elem == self.docId //MATCH USER ID
                                            })
                                            if(matchId > -1){                             
                                                self.allUserRole[searchInd].assignUser.splice(matchId,1)
                                                let updateRoleData = {
                                                    'assignUser': self.allUserRole[searchInd].assignUser
                                                }
                                                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,self.allUserRole[searchInd].id,updateRoleData,()=>{
                                                    // console.log(updateRes,"delete next-->[update userRole]")
                                                })    
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        setTimeout(()=>{
                            var newFiles = []
                            var docs = []
                            self.employeeDocs.forEach(imgs => {
                                var found=false
                                self.prevDocs.forEach(imgs2=>{
                                    // console.log("IMages",imgs2.imageName , imgs.imageName)
                                    if(imgs2.imageName == imgs.imageName)
                                    {
                                        found=true
                                    }
                                })
                                if(!found)
                                {
                                    // console.log("New Image",imgs)
                                    newFiles.push(imgs)
                                }
                            });
    
                            // console.log("newFiles",newFiles.length)
    
                            if(newFiles.length!=0)
                            {
                                newFiles.forEach(img=>{
                                    // console.log(img.imageName,img.imageUrl)
                                    uploadImageFile(self.docFolderName,img.imageName,img.imageUrl,res=>{
                                        self.employeeDocs.forEach(vals=>{
                                            if(vals.imageName==img.imageName)
                                            {
                                                vals.imageName = res.imageName
                                                vals.imageUrl = res.imageUrl
                                            }
                                        })
                                        docs.push(res)
    
                                        if(docs.length == newFiles.length)
                                        {
                                            self.saveData()
                                        }
                                    })
                                })
                            }
                            else
                            {
                                self.saveData();
                            }
    
                        },1000)
                    }
                    else
                    {
                        // console.log("Success",success)
                        self.$root.$emit('showToastMessage','Please provide all the document names.','danger')
                        self.$root.$emit('stopSpinner')
                    }
                }
                else
                {
                    self.representative = typeof self.representative == 'string' ? self.representative : self.representative.id;
                    // console.log("Success",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },

        saveData()
        {
            var self = this
            var currentUsers = JSON.parse(localStorage.getItem('currentUser'));
            if(self.imageFile.length!=0)
                {
                    self.uploadingImage = true
                    // console.log("Image Data",self.imageFile[0].name,self.imageFile)
                    removeImage(self.folderName,self.imageName,callBack=>{
                        uploadImageFile(self.folderName,self.imageFile[0].name,self.imageFile[0],res=>{
                        db
                            .collection(dbCollections.USERS)
                            .doc(self.docId)
                            .update({
                                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

                                'imageUrl':res.imageUrl,
                                'imageName':res.imageName,

                                'name':self.name,
                                'firstName':self.firstName,
                                'lastName':self.lastName,
                                'employeeRole':self.role,
                                'role':'employee',
                                'personalEmail':self.personalEmail,
                                'companyEmail':self.companyEmail,
                                'personalPhoneNo':self.personalPhoneNo,
                                'emergencyPhoneNo':self.emergencyPhoneNo,
                                'dob':self.dob,
                                'dateOfJoining':self.dateOfJoining,
                                'gender':self.gender,
                                'aadharCardNo':self.aadharCardNo,

                                'employeeDocs':self.employeeDocs,

                                'addressLine1':self.addressLine1,
                                'addressLine2':self.addressLine2,
                                'country':self.country,
                                'state':self.state,
                                'city':self.city,
                                'pincode':self.pincode,
                                'representative':self.representative||'',
                                'isPhoneNumberShow': self.isPhoneNumberShow,
                                'countryPhoneCode': self.selectedCountry,
                                'countryPhoneCodeEmergency': self.selectedCountryEmergency
                            })
                            .then(()=>{
                                if(self.oldRepresentation && self.representative && self.oldRepresentation.id !== self.representative.id) {
                                    let notificationObj = {
                                        title: `Employee Edited`,
                                        type: 'employeeEdited',
                                        userId: self.docId,
                                        currentUser:firebase.auth().currentUser.uid
                                    }
                                    if(self.representative) {
                                        notificationObj.message = `Your representative has been changed to ${self.representative.name} by ${currentUsers.fullName}`;
                                    } else {
                                        notificationObj.message = `Your representative has been changed to ${currentUsers.fullName}`;
                                    }
                                    this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                    }).catch((error)=>{
                                        console.error(error);
                                    })
                                }
                                self.uploadingImage = false
                                self.oldPersonalEmail = self.personalEmail
                                if(self.role !== null){
                                    let updateAssingArry = [];
                                    let getInd = self.allUserRole.findIndex(vals=>{
                                        // console.log(vals.id, "==", self.role.id)
                                        return vals.id == self.role.id
                                    })
                                    if(getInd > -1){
                                        updateAssingArry = self.allUserRole[getInd].assignUser
                                    }
                                    updateAssingArry.push(self.docId)
                                    let updateField = {
                                        'assignUser': updateAssingArry
                                    }
                                    updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,self.role.id,updateField,updateRes=>{
                                        // console.log('JYOTI',updateRes)
                                        self.$router.push({name:'employee-information',params:{name:self.$route.params.name,id:self.$route.params.id}})
                                        self.$root.$emit('showToastMessage','Employee Update successfully.','success')
                                        self.$root.$emit('stopSpinner')
                                    }) 
                                }else{
                                    self.$router.push({name:'employee-information',params:{name:self.$route.params.name,id:self.$route.params.id}})
                                    self.$root.$emit('showToastMessage','Employee Update successfully.','success')
                                    self.$root.$emit('stopSpinner')
                                }
                            })
                            .catch(error=>{
                                self.$root.$emit('stopSpinner')
                                self.$root.$emit('showToastMessage',error.message,'danger')
                                console.log(error)
                            })
                    })
                    })
                }
                else
                {
                        db
                        .collection(dbCollections.USERS)
                        .doc(self.docId)
                        .update({
                            'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            'firstName':self.firstName,
                            'lastName':self.lastName,
                            'name':self.name,
                            'employeeRole':self.role,
                            'role':'employee',
                            'personalEmail':self.personalEmail,
                            'companyEmail':self.companyEmail,
                            'personalPhoneNo':self.personalPhoneNo,
                            'emergencyPhoneNo':self.emergencyPhoneNo,
                            'dob':self.dob,
                            'dateOfJoining':self.dateOfJoining,
                            'gender':self.gender,
                            'aadharCardNo':self.aadharCardNo,

                            'employeeDocs':self.employeeDocs,

                            'addressLine1':self.addressLine1,
                            'addressLine2':self.addressLine2,
                            'country':self.country,
                            'state':self.state,
                            'city':self.city,
                            'pincode':self.pincode,
                            'representative':self.representative||'',
                            'isPhoneNumberShow': self.isPhoneNumberShow,
                            'countryPhoneCode': self.selectedCountry,
                            'countryPhoneCodeEmergency': self.selectedCountryEmergency
                        })
                        .then(()=>{
                            if(self.oldRepresentation && self.representative && self.oldRepresentation.id !== self.representative.id) {
                                let notificationObj = {
                                    title: `Employee Edited`,
                                    type: 'employeeEdited',
                                    userId: self.docId,
                                    currentUser:firebase.auth().currentUser.uid
                                }
                                if(self.representative) {
                                    notificationObj.message = `Your representative has been changed to ${self.representative.name} by ${currentUsers.fullName}`;
                                } else {
                                    notificationObj.message = `Your representative has been changed to ${currentUsers.fullName}`;
                                }
                                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                }).catch((error)=>{
                                    console.error(error);
                                })
                            } 
                            if(self.role !== null){
                            let updateAssingArry = [];
                            let getInd = self.allUserRole.findIndex(vals=>{
                                // console.log(vals.id, "==", self.role.id)
                                return vals.id == self.role.id
                            })
                            if(getInd > -1){
                                updateAssingArry = self.allUserRole[getInd].assignUser
                            }
                            updateAssingArry.push(self.docId)
                            let updateField = {
                                'assignUser': updateAssingArry
                            }
                            updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,self.role.id,updateField,updateRes=>{
                                // console.log('JYOTI',updateRes)
                                self.$router.push({name:'employee-information',params:{name:self.$route.params.name,id:self.$route.params.id}})
                                self.$root.$emit('showToastMessage','Employee Update successfully.','success')
                                self.$root.$emit('stopSpinner')
                            })  
                            }else{
                                self.$router.push({name:'employee-information',params:{name:self.$route.params.name,id:self.$route.params.id}})
                                self.$root.$emit('showToastMessage','Employee Update successfully.','success')
                                self.$root.$emit('stopSpinner')     
                            }                                  
                        })
                        .catch(error=>{
                            self.$root.$emit('stopSpinner')
                            self.$root.$emit('showToastMessage',error.message,'danger')
                            console.log(error)
                        })
                }
        },
        allDocsValid()
        {
            var self = this

            var valid = true

            self.employeeDocs.forEach(data=>{
                if(data.document_name=='')
                {
                    valid = false
                }
            })

            return valid;
        },
    },
    computed:{
        checkEmail()
        {
            var found = false
            var self = this

            if(self.personalEmail!='' && self.oldPersonalEmail != self.personalEmail)
            {
                self.allEmails.forEach(mail=>{
                    if(mail==self.personalEmail)
                    {
                        found = true
                    }
                })
            }

            return found
        },
        checkCompanyEmail()
        {
            var found = false
            var self = this

            if(self.companyEmail!='' && self.oldCompanyEmail != self.companyEmail)
            {
                self.allCompanyEmails.forEach(mail=>{
                    if(mail==self.companyEmail)
                    {
                        found = true
                    }
                })
            }

            return found
        },
        dataMeta(){
            const localItemsCount = this.docTable ? this.docTable.length : 0
            if(this.filter !== "" && this.filter !== null){
              // console.log("search",this.totalRows)             
              return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.docTable.length,
              }
            }else{
            // console.log("not search")
                 return {
                    from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: this.perPage * (this.currentPage - 1) + localItemsCount,
                    of: this.employeeDocs.length,
                }
            } 
           
        }
    }
}
</script>

<style scoped>
.uploadDoc{
    text-align: center;
    padding: 0;
    border: 1px #c1c1c1 dashed;
    background-color: #ededed;
    width: 100%;
    margin: 0 auto;
    padding: 9px;
    height: 145px;
    border-radius: 5px;
    display: block;
    line-height: 130px;
}
span.uploadDoc img {
    /* width: 22px; */
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translateX(-50%);
}
span.uploadDoc a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.phoneconfirmation .custom-checkbox {
    margin-bottom: 10px !important;
}
@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>